.App {
    text-align: center;
}

div.noPadding {
    padding-left: 0;
    padding-right: 0;
}

div.noMargin {
    margin-left: 0;
    margin-right: 0;
}

